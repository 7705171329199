body{
  background-color: #eceff1;
}

a{
  text-decoration: none;
}

.btn{
  border-radius: 100px;
}

.border-6{
  border-width: 6px!important;
}

.border-8{
  border-width: 8px!important;
}

.accordion-dark .accordion-button{
  color : #FFFFFF;
  background-color: #212529;
}

.accordion-dark .accordion-button:not(.collapsed){
  color : #00bcd4;
}

.accordion-button::after{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

.accordion-button:not(.collapsed)::after{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300bcd4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

.accordion-button:not(.collapsed):disabled:after{
  background-image: none;
}

.nav-pills .nav-link.active{
  color: #FFF;
  border: none;
  border: 1px solid #00bcd4;
}

.nav-pills .nav-link{
  color: #212529;
  border-radius: 100px;
  border: 1px solid #212529;
}

.nav-pills .nav-link:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.border-primary{
  border-color: #00bcd4 !important;
}

.btn-primary{
  background-color: #00bcd4;
  border-color: #00bcd4;
}

.btn-success{
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.btn-outline-primary{
  border-color: #00bcd4;
  color: #00bcd4;
}

.text-primary{
  color: #00bcd4 !important;
}

.alert-primary {
  color: #00bcd4;
  background-color: #baeef3;
  border-color: #8ae7f0;
}

.bg-primary{
  background-color: #00bcd4 !important;
}

.btn-primary:hover, .btn-outline-primary:hover{
    background-color: #0097a7;
    border-color: #0097a7;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    background-color: #00bcd4;
}

.nav-link{
    color : #ffffff
}

.autocomplete-item{
  cursor: pointer;
}

.autocomplete-item:hover{
  background-color: #00bcd4;
  color: #FFF;
}

.modal-header{
    background-color: #00bcd4;
}

.modal-title{
    color : #fff;
}

.bg-light{
    background-color: #cfcfdc!important
}

.modal-content{
  border : 0;
}

.card {
    margin: 5px 10px;
    border: 1px solid #bbb;
    box-shadow: 3px 2px 5px 0px lightgrey;
    background-color: white;
    font-family: 'Helvetica';
    position: relative;
    display: inline-block;
  }
  
  .card-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .scroll {
    padding: 5px;
    height: 235px;
    line-height: 235px;
    position: absolute;
    top:0;
    bottom: 0;
    flex-basis: 0;
    z-index: 5;
    background-color: rgba(225,225,225,0.8);
    color: darkgrey;
  }
  .scroll-next {
    right: 10px;
  }
  
  .scrolling-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    background-color: #eee;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .scrolling-wrapper::-webkit-scrollbar {
    display: none;
  }

  *::-webkit-scrollbar-track{
    border-radius: 10px;
    background-color: transparent;
  }

  *::-webkit-scrollbar{
    width: 12px;
    background-color: transparent;
    border-left : 1px solid #CCC;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  *::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background-color: #00bcd4;
  }

  /* LOADING -------------------------------------LOADING-------------------------------------LOADING-----------------------LOADING*/

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 40px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 15px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #00bcd4;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

  /* LOADING FINE ---------------------------------LOADING FINE ----------------------LOADING FINE ------------------ FINE */
  

  .dashboardCard {
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(82, 82, 82, 0.5);
    -moz-box-shadow: 0px 0px 10px 0px rgba(82,82,82,0.5);
    box-shadow: 0px 0px 10px 0px rgba(82, 82, 82, 0.5);
  }