@keyframes blinker {
    50% {
        opacity: 0.3;
    }

    70% {
        opacity: 0.8;
    }
}
.blinkDot {
    animation: blinker 2s linear infinite;
}