/**calendarHeader calendarHeaderLabel



calendarBody calendarSideCell
*/
/**
.calendarBody.calendarShiftCell:nth-of-type(odd){
    background-color: rgba(0,0,0,0.05);
}*/

.calendar .calendarHeaderLabel{
    min-width: 200px;
}

.calendar .row .calendarControlPrev, .calendar .row .calendarControlNext{
    position: relative;
    width: 20px;
    max-width: 20px;
    font-size: 16px;
    padding: 20px 4px;
    background: rgba(0,0,0,0.05);
}

.calendar .row .calendarControlPrev{
    margin-left: -20px;
    border-right: 1px solid rgba(0,0,0,0.2);
}

.calendar .row .calendarControlNext{
    border-left: 1px solid rgba(0,0,0,0.2);
}

.calendar .row .calendarControlPrev:hover, .calendar .row .calendarControlNext:hover{
    background: rgba(0,0,0,0.1);
}

.calendarCol:nth-of-type(even){
    background-color: rgba(0,0,0,0.05);
}

.calendarCol{
    cursor: pointer;
}

.calendarCol:hover{
    position: relative;
}

.calendarCol:hover .calendarHeaderDate{
    background: rgba(233, 30, 99,0.9);
    color :#FFFFFF;
}

.calendarCol:hover::after{
    content: 'RICHIEDI TURNO';
    position: absolute;
    text-align: center;
    top: 66px;
    padding-top: 40px;
    color: white;
    font-weight: 600;
    bottom: 0;
    left: 0;
    white-space: pre-wrap;
    right: 0;
    background: rgba(233, 30, 99, 0.9);
}

.calendarBody.calendarShiftCell{
    text-align: center;
    line-height: 100%;
}

.calendar .calendarSideCell, .calendarBody.calendarShiftCell{
    border-bottom: 2px solid #CCC;
}

.calendar .calendarSideCell, .calendarHeader.calendarHeaderLabel{
    padding: 0 20px;
}

.calendar .row>*{
    padding: 0;
    margin: 0;
}

.calendar .calendarCol{
    flex: 1 0 0%;
    margin: 0;
}

.calendar .calendarFloatingLabel{
    font-size: 12px;
    font-weight: bold;
    color: #FFFFFF;
    padding-left: 10px;
}

.calendar .calendarDividerCell{
    min-height:20px;
    max-height:20px;
}

.calendar .calendarRow{
    display:flex;
    margin: 0;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.calendar .calendarHeaderDate,.calendar .calendarHeaderLabel{
    height: 66px;
}

.calendarHeader.calendarHeaderLabel{
    line-height: 100px;
}

.calendar .day, .calendar .month, .calendar .year{
    text-align: center;
    display: block;
}
.calendar .day{
    font-size: 22px;
    line-height: 32px;
    height: 24px;
}
.calendar .month{
    font-size: 14px;
    height: 18px;
}
.calendar .year{
    font-size: 12px;
    height: 12px;
}

.calendar .calendarRow::-webkit-scrollbar {
    display: none;
}

