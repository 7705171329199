.DateInfinity {
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
    position: absolute;
    top : 56px;
    left : 0;
    right : 0;
    bottom: 0;
}

.DateInfinity img {
    width: 100px;
    height: 100px;
}

.DateInfinity div.item.selected {
    background-color: rgba(0, 255, 255, 0.151);
}

.DateInfinity .item-date {
    display: inline-block;
}

.DateInfinity .item.today {
    background-color : #00bcd4;
}

.DateInfinity .item .item-day{
    padding-left : 25px;
    padding-top: 8px;
}

.DateInfinity .item{
    cursor: pointer;
}

.DateInfinity .item:hover{
    background-color: #212529;
    color : #00bcd4;
}

.DateInfinity .item .item-monthYear{
    padding-left : 0px;
    padding-top: 16px;
}

.DateInfinity .item:hover .item-event {
    opacity: 0.8;
}

.DateInfinity .item-event {
    display: inline-block;
    background-color: #dc3545;
    color: white;
    border-radius: 5%;
    height: auto;
    border: none;
    margin-left: 5px;
}